import { Injectable, inject } from '@angular/core';
import { AuthService, PermissionsService } from '@mca/auth/api';
import { RxState } from '@rx-angular/state';
import { McaPageService } from '../mca-page.service';
import { EMPTY, catchError, combineLatest, filter, map, switchMap } from 'rxjs';
import { FUNDING_ROUDING, ScheduleFunctions } from './schedule/schedule-functions';
import { McaScheduleService } from './schedule/mca-schedule.service';
import { PaymentFrequency } from '@mca/shared/domain';
import { IsoCommissionsService } from './iso-commissions.service';
import { MCAProgramTypes } from '../../entities/mca-consts';
import { CommUserSet, OfferCommissionImpl, OfferTemplate } from '../../entities/offer';
import { calcMaxIncrementalPayment } from '../mcautils';
import { MessageService, SelectItem } from 'primeng/api';
import { OfferService } from '../../infrastructure/offer.service';
import { selectSlice } from '@rx-angular/state/selections';

interface IsoOutstandingState {
  disabled: boolean;
  canEdit: boolean;
  offerTemplateOptions: SelectItem[];
}

@Injectable()
export class IsoOutstandingService extends RxState<IsoOutstandingState> {
  private mcaPageService = inject(McaPageService);
  private permissionsService = inject(PermissionsService);
  private mcaScheduleService = inject(McaScheduleService);
  private isoCommissionsService = inject(IsoCommissionsService);
  private messageService = inject(MessageService);
  private offerService = inject(OfferService);
  private authService = inject(AuthService);

  scheduleOfferForm$ = this.mcaScheduleService.getSchedule$().pipe(switchMap(schedule => schedule.offerForm$));
  scheduleState$ = this.mcaScheduleService.getSchedule$().pipe(switchMap(schedule => schedule.state$));
  get mca() {
    return this.mcaPageService.get('mca');
  }
  get schedule() {
    return this.mcaScheduleService.getSchedule();
  }
  state$ = combineLatest([this.mcaPageService.getLoadedState(), this.scheduleState$, this.select()]).pipe(
    map(([mcaState, scheduleState, localState]) => ({ ...mcaState, ...scheduleState, ...localState })),
  );

  constructor() {
    super();
    this.set({ disabled: true, canEdit: this.permissionsService.hasPermission('offer_underwriting_w') });
    this.connect('disabled', this.mcaPageService.select('loaded').pipe(map(loaded => !loaded || !this.get('canEdit'))));
    this.connect(
      'offerTemplateOptions',
      this.mcaPageService.select('offerTemplates').pipe(
        map(offerTemplates => [
          { label: '', value: null },
          ...offerTemplates
            .map(offerTemplate => ({ label: offerTemplate.name, value: offerTemplate, disabled: !offerTemplate.is_active }))
            .sort((t1, t2) => (t1.label < t2.label ? -1 : 1))
            .sort((t1, t2) => (t1.disabled === t2.disabled ? 0 : t1.disabled ? 1 : -1)),
        ]),
      ),
    );
    this.watchOfferLoad();
  }

  paymentFreqLabel = (freq: PaymentFrequency | 0) => (freq === PaymentFrequency.daily ? '# days' : '# weeks');

  selectOfferTemplate(template: OfferTemplate) {
    if (!template) {
      this.resetTemplate();
      return;
    }
    this.mca.position.program = template.funding_type;
    // reset fundedAmt if template was changed from consolidation to position
    const scheduleState = this.schedule.state;
    const result = ScheduleFunctions.copyScheduleState(scheduleState);
    if (scheduleState.offerForm.program === MCAProgramTypes.consolidation) {
      result.offerForm.fundedAmt = 0;
    }
    const dbaFixedCF = this.authService.getFixedFee(this.mca.dbaRec);
    Object.assign(result.offerForm, {
      program: template.funding_type,
      fixPayment: false,
      days: template.crate_days * template.withdrawal_freq,
      rate: template.crate,
      daysDisc: template.drate_days * template.withdrawal_freq,
      discountFactorRate: template.drate,
      selectedWithdFreq: template.withdrawal_freq,
      fee: dbaFixedCF ? 0 : template.contract_fee_pct,
      fixed_cf: dbaFixedCF || template.fixed_cf,
      numberOfIncrements: template.number_of_increments || (template.increments?.length ?? 0),
      incrementFrequency: template.deposit_freq ?? 0,
      templateId: template.id,
      switch_to_daily_payments: template.switch_to_daily_payments,
    });
    if (template.funding_type === MCAProgramTypes.consolidation || template.use_consolidation_increments) {
      Object.assign(result, ScheduleFunctions.calcSuggested(result, this.mca));
    }
    result.commissions = { ...result.commissions, ...OfferCommissionImpl.templateToCommissions(template) };
    result.programIncrements = template.number_of_increments ? [] : template.increments ?? [];
    this.isoCommissionsService.get('commUserSets').forEach(item => this.setUserCommissionsItem(template, item));
    result.sameIncrementAmount = !!template.number_of_increments;
    this.schedule.updateState(result);
    if (template.funding_type === MCAProgramTypes.incrementalDeal) {
      this.schedule.stateUpdate$.subscribe(() => {
        this.updateIncrements(template);
      });
    }
    this.schedule.stateUpdate$.subscribe(() => {
      this.calcSchedulesWithExposure();
    });
  }

  calcSchedulesWithExposure() {
    this.mcaScheduleService.updateSchedules();
    this.schedule.stateUpdate$.subscribe(() => {
      this.mcaScheduleService.updateExposures();
    });
  }

  private watchOfferLoad() {
    const depositsUpdate$ = this.mcaScheduleService.getSchedule$().pipe(
      switchMap(schedule => schedule.stateUpdate$),
      selectSlice(['deposits']),
      map(({ deposits }) => deposits),
    );
    this.hold(
      this.mcaPageService.select('loadedOffers').pipe(
        filter(Boolean),
        switchMap(() => depositsUpdate$),
      ),
      deposits => {
        const template = this.get('offerTemplateOptions').find(option => option.value?.id === this.schedule.offerForm.templateId)?.value;
        if (template?.use_consolidation_increments) {
          this.schedule.updateState({
            programIncrements: deposits.map(deposit => (deposit.ammount / this.schedule.offerForm.fundedAmt) * 100),
          });
        }
      },
    );
  }

  private updateIncrements(template: OfferTemplate) {
    if (template.use_consolidation_increments) {
      return;
    } else if (template.number_of_increments) {
      this.calcIncrementalFunding();
    } else {
      this.requestIncrementalFunding(template);
    }
  }

  calcIncrementalFunding() {
    if (this.schedule.offerForm.program !== MCAProgramTypes.incrementalDeal || !this.schedule.state.sameIncrementAmount) {
      return;
    }
    const fundedAmt =
      Math.ceil(this.schedule.offerForm.fundedAmt / FUNDING_ROUDING / this.schedule.offerForm.numberOfIncrements) *
      this.schedule.offerForm.numberOfIncrements *
      FUNDING_ROUDING;
    if (fundedAmt !== this.schedule.offerForm.fundedAmt) {
      this.schedule.updateOfferForm({ fundedAmt });
      this.messageService.add({ severity: 'warn', summary: 'Funding amount updated' });
    }
    this.schedule.stateUpdate$.subscribe(() => {
      this.schedule.updateSchedule(true);
    });
  }

  private resetTemplate() {
    this.schedule.updateOfferForm({
      templateId: null,
      fundedAmt: 0,
      fee: 0,
      rate: 1.5,
      discountFactorRate: 0,
      days: 1,
      daysDisc: 1,
      selectedWithdFreq: PaymentFrequency.daily,
      selectedDepFreq: PaymentFrequency.weekly,
    });
  }

  private setUserCommissionsItem(template: OfferTemplate, userSet: CommUserSet) {
    const users = userSet?.users;
    if (!users?.length) {
      return;
    }
    this.isoCommissionsService
      .setOfferTemplateCommissionsOnUserSet$(userSet.setindex, template)
      .subscribe(() => this.isoCommissionsService.calcUserCom());
  }

  private requestIncrementalFunding(template: OfferTemplate) {
    this.mcaPageService
      .getIndustry()
      .pipe(
        filter(industry => {
          if (!industry) {
            this.messageService.add({ severity: 'error', summary: 'No industry to calc funding amount' });
            return false;
          }
          if (!industry.max_withdrawal_rate) {
            this.messageService.add({ severity: 'error', summary: 'Industry has no max withdrawal rate' });
            return false;
          }
          return true;
        }),
        map(industry => industry?.max_withdrawal_rate ?? 0),
        switchMap(max_withdrawal_rate => {
          const payment = calcMaxIncrementalPayment(this.mca, max_withdrawal_rate);
          if (!payment) {
            this.messageService.add({
              severity: 'error',
              summary: 'Cannot calc payment',
              detail: 'Please check bank deposits and outstandings',
            });
          }
          return payment
            ? this.offerService
                .calcIncrementalTemplateFa(this.mca.id, {
                  incr_schedule_pct:
                    (template.use_consolidation_increments ? this.schedule.state.programIncrements : template.increments) ?? [],
                  max_fa: template.max_funding_amount ?? 0,
                  max_exposure_pct: template.max_exposure ?? 0,
                  payment,
                  rate: this.schedule.offerForm.discountFactorRate || this.schedule.offerForm.rate,
                  contract_fee_pct: this.schedule.offerForm.fee,
                  deposit_freq: this.schedule.offerForm.incrementFrequency,
                  payment_freq: this.schedule.offerForm.selectedWithdFreq,
                  days: this.schedule.offerForm.daysDisc || this.schedule.offerForm.days,
                })
                .pipe(catchError(() => EMPTY))
            : EMPTY;
        }),
        filter(({ fa }) => fa !== this.schedule.offerForm.fundedAmt),
      )
      .subscribe(({ fa }) => {
        this.schedule.updateOfferForm({ fundedAmt: fa });
        this.schedule.stateUpdate$.subscribe(() => {
          this.schedule.updateSchedule(true);
        });
        this.messageService.add({ severity: 'warn', summary: 'Funding amount updated' });
      });
  }
}

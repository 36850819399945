import { AuthService } from '@mca/auth/domain';
import { HolidaysService } from '@mca/references/api';
import { PaymentFrequency } from '@mca/shared/domain';
import { removeObjectValues, roundMoney } from '@mca/shared/util';
import { MessageService } from 'primeng/api';
import {
  BehaviorSubject,
  EMPTY,
  Subject,
  asyncScheduler,
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  observeOn,
  pairwise,
  takeUntil,
  shareReplay,
  skipWhile,
  switchMap,
} from 'rxjs';
import { MCAProgramTypes } from '../../../entities/mca-consts';
import { McaOfferParams, McaScheduleState, McaScheduleStatePartial, ScheduleOfferForm } from '../../../entities/mca-schedule';
import { getMcaActiveCommissions } from '../../../entities/mcarec';
import { McaOffer, OfferCommissionImpl } from '../../../entities/offer';
import { ExpectedExposureData, ExposureRec, calcExposureTotals } from '../../../entities/offer-mca-rec';
import { McaService } from '../../../infrastructure/mca.service';
import { McaPageService } from '../../mca-page.service';
import { IsoUserMapService } from '../iso-user-map.service';
import { McaScheduleConsolidationService } from './mca-schedule-consolidation.service';
import { McaScheduleFixedService } from './mca-schedule-fixed.service';
import { McaScheduleIncrementalService } from './mca-schedule-incremental.service';
import { FUNDING_ROUDING, RateType, ScheduleFunctions, GenerateWithdrawalsCommand } from './schedule-functions';
import { McaScheduleIncrementalConsolidationService } from './mca-schedule-incremental-consolidation.service';

export class McaSchedule {
  destroy$ = new Subject<void>();

  private fixedNumberOfPaymetns = 0;

  // changes to offer form triggers recalc of other values
  private offerFormSubject = new BehaviorSubject<ScheduleOfferForm>({
    program: MCAProgramTypes.deal,
    fundedAmt: 0,
    fee: 0,
    fixed_cf: 0,
    rate: 1.5,
    days: 1,
    fixPayment: false,
    withdrAmt: 0,
    discountFactorRate: 0,
    daysDisc: 1,
    selectedDepFreq: PaymentFrequency.weekly,
    selectedWithdFreq: PaymentFrequency.daily,
    depStartDate: new Date(),
    withdrStartDate: this.holidaysService.addBusinessDays(new Date(), 1),
    numberOfIncrements: 3,
    incrementFrequency: 20,
    sameIncrementAmount: false,
    templateId: null,
    switch_to_daily_payments: false,
  });

  private offerParamsSubject = new BehaviorSubject<McaOfferParams>({
    // derived values
    feeAmt: 0,
    expectedRet: 0,
    additionalAmt: 0,
    netToMerchantAmt: 0,
    expAsFundAmt: 0,
    savingsPct: 0,
    expectedRetDisc: 0,
    trueRate: 0,
    trueRateDisc: 0,
    buyOutAmt: 0,
    sameIncrementAmount: false,

    // intermediate values
    totPayment: 0,
    totCurrentBalance: 0,
    comPct: 0,
    comAmt: 0,
    exposure: 0,

    exposureRec: new ExposureRec(),
    commissions: {
      iso_commission_rtr_pct: 0,
      iso_contract_fee_pct: 0,
      isorep_commission_pct: 0,
      isorep_contract_fee_pct: 0,
      ins_commission_rtr: 0,
      ins_commission_contract_fee_pct: 0,
      commission_comm_rtr_pct: 0,
      commission_contract_fee_pct: 0,
      cf_commission_rtr_pct: 0,
      cf_contract_fee_pct: 0,
    },
    isoOfferVerified: false,
    deposits: [],
    withdrawals: [],
    programIncrements: [],
  });

  get offerForm() {
    return this.offerFormSubject.value;
  }

  get offerParams() {
    return this.offerParamsSubject.value;
  }

  get offerForm$() {
    return this.offerFormSubject.pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)));
  }

  get offerParams$() {
    return this.offerParamsSubject.pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)));
  }

  get scheduleType() {
    switch (this.offerForm.program) {
      case MCAProgramTypes.consolidation:
        return this.mcaScheduleConsolidation.withContext(this);
      case MCAProgramTypes.incrementalDeal:
        if (this.getTemplate()?.use_consolidation_increments) {
          return this.mcaScheduleIncrementalConsolidation.withContext(this);
        }
        return this.mcaScheduleIncremental.withContext(this);
      default:
        return this.mcaScheduleFixed.withContext(this);
    }
  }

  get mca() {
    return this.mcaPageService.get('mca');
  }

  get minLastWithdrawal() {
    return +(this.authService.systemConfig?.minLastPaymentAmount ?? 0);
  }

  get state(): McaScheduleState {
    return { offerForm: { ...this.offerForm }, ...this.offerParams };
  }
  state$ = combineLatest([this.offerForm$, this.offerParams$]).pipe(
    map(([offerForm, offerParams]) => ({
      offerForm,
      ...offerParams,
    })),
    shareReplay(1),
  );

  get stateUpdate$() {
    return this.state$.pipe(debounceTime(0), first());
  }

  constructor(
    private holidaysService: HolidaysService,
    private mcaPageService: McaPageService,
    private messageService: MessageService,
    private mcaService: McaService,
    private mcaScheduleFixed: McaScheduleFixedService,
    private mcaScheduleConsolidation: McaScheduleConsolidationService,
    private mcaScheduleIncremental: McaScheduleIncrementalService,
    private mcaScheduleIncrementalConsolidation: McaScheduleIncrementalConsolidationService,
    private isoUserMapService: IsoUserMapService,
    private authService: AuthService,
  ) {
    this.watchFormChanges();
    this.initData();
    this.watchDerivedValues();
  }

  updateOfferForm(offerForm: Partial<ScheduleOfferForm>) {
    // console.warn(offerForm);
    this.offerFormSubject.next({ ...this.offerForm, ...offerForm });
  }

  updateState(state: McaScheduleStatePartial) {
    const { offerForm, ...offerParams } = state;
    if (offerForm) {
      this.updateOfferForm(offerForm);
    }
    if (Object.keys(offerParams).length) {
      this.offerParamsSubject.next({ ...this.offerParams, ...offerParams });
    }
  }

  updateExposure() {
    const setIndex = this.mcaPageService.get('loadedOffers')?.[0]?.commissionset ?? 1;
    this.getOfferData$(setIndex)
      .pipe(
        switchMap(offer => this.mcaService.calcOfferDataExposure(this.mca.id, offer)),
        catchError(() => EMPTY),
      )
      .subscribe(weeks => this.applyExposure(weeks));
  }

  applyExposure(weeks: ExpectedExposureData[]) {
    const exposureRec = new ExposureRec();
    exposureRec.schedule = weeks;
    calcExposureTotals(exposureRec);
    const expAsFundAmt = this.offerForm.fundedAmt ? (exposureRec.totExposure / this.offerForm.fundedAmt) * 100 : 0;
    const resultExposure = { exposureRec, exposure: exposureRec.totExposure, expAsFundAmt };
    this.updateState(resultExposure);
  }

  buyOutAmt() {
    return this.mca?.outstandingLoans.filter(loan => loan.buyout).reduce((acc, loan) => acc + loan.balanceRemains, 0);
  }

  updateSchedule(fixPayments = false) {
    this.resetSchedule();
    if (fixPayments) {
      const days = this.offerForm.discountFactorRate ? this.offerForm.daysDisc : this.offerForm.days;
      this.fixedNumberOfPaymetns = days / this.offerForm.selectedWithdFreq;
    }
    this.generateSchedule();
    this.fixedNumberOfPaymetns = 0;
  }

  private generateSchedule(discounted?: boolean) {
    const useDiscount = discounted ?? !!this.offerForm.discountFactorRate;
    if (this.offerForm.fundedAmt) {
      this.calcWithdrawals(useDiscount);
      this.calcDeposits();
    }
  }

  validate() {
    const messages = [];
    if (!this.offerForm.fundedAmt) {
      messages.push('"Funding Amt" is required');
    }
    if (!this.offerForm.rate) {
      messages.push('"C Rate" is required');
    }
    if (!this.offerForm.withdrAmt) {
      messages.push('"Payment$" is required');
    }
    if (!this.offerForm.depStartDate) {
      messages.push('"Dep Start Date" is required');
    }
    if (!this.offerForm.withdrStartDate) {
      messages.push('"Withdr Start Date" is required');
    }
    if (!this.offerForm.days) {
      messages.push('"# days" is required');
    }
    return messages;
  }

  applyOffer(offer: McaOffer) {
    const loadedState = ScheduleFunctions.offerDataToScheduleState(this.state, offer);
    // regenerate schedule and merge it with data from loaded offer
    const loadedDeposits = McaOffer.expandPayments(offer.deposit);
    const outstandingTotals = ScheduleFunctions.calcOutstandingTotals(offer.funding_type, this.mca);
    const resultState = {
      ...this.state,
      ...loadedState,
      offerForm: {
        ...this.state.offerForm,
        ...loadedState.offerForm,
        incrementFrequency: offer.deposit_freq,
        numberOfIncrements: loadedDeposits.length,
      },
      ...outstandingTotals,
    };
    this.updateState(resultState);
    // wait for calc of expectedRet - it's required for withdrawals calc
    this.stateUpdate$.subscribe(() => {
      this.generateScheduleFromOffer(offer);
    });
  }

  calcSuggested() {
    this.updateState(ScheduleFunctions.calcSuggested(this.state, this.mca));
  }

  private generateScheduleFromOffer(offer: McaOffer) {
    const loadedDeposits = McaOffer.expandPayments(offer.deposit);
    const loadedWithdrawals = McaOffer.expandPayments(offer.withdrawal);
    this.mca.num_payments = loadedWithdrawals.length;
    // calc schedule without optimization of payments to keep them as is in offer
    this.scheduleType.fixedNumberOfDeposits = this.offerForm.numberOfIncrements;
    this.fixedNumberOfPaymetns = loadedWithdrawals.length;
    try {
      const rateType = offer.rates[1]?.rate && offer.rates[1].rate !== offer.rates[0].rate ? RateType.D : RateType.C;
      const updates = ScheduleFunctions.setupDayCountPayment(this.state, rateType, this.fixedNumberOfPaymetns);
      this.updateState(updates);
    } catch (e: any) {
      this.showCalcError(e);
    }
    this.updateSchedule(true);
    this.scheduleType.fixedNumberOfDeposits = 0;
    this.fixedNumberOfPaymetns = 0;
    if (loadedDeposits.length !== this.state.deposits.length) {
      this.updateSchedule(true);
    }
    // rewrite schedule incremets with data (fees) from offer
    const removeUndefinedFields = (data: object) => removeObjectValues(data, v => v === undefined);
    try {
      for (let i = 0; i < this.state.deposits.length; i++) {
        Object.assign(this.state.deposits[i], removeUndefinedFields(loadedDeposits[i]));
      }
      for (let i = 0; i < this.state.withdrawals.length; i++) {
        Object.assign(this.state.withdrawals[i], removeUndefinedFields(loadedWithdrawals[i]));
      }
    } catch (e) {
      // schedule on FE is not source of truth, so we must be sure that increments can be recalc
      console.error('Offer schedule does not match current schedule:\n', e);
      this.messageService.add({ severity: 'error', summary: 'Offer schedule does not match current schedule' });
      this.updateSchedule(true);
    }
  }

  getOfferData$(setIndex: number) {
    const commUsers = this.mca.mcaCommisionUsers.filter(user => user.setindex === setIndex);
    return this.isoUserMapService.getUserSetCommissionsFromOfferCommissions$(this.offerParams.commissions, commUsers).pipe(
      map(users => {
        const offer = new McaOffer();
        offer.setMcaData(this.mca);
        offer.setCommSetData({ users });
        const { deposits, withdrawals } = this.state;
        offer.setScheduleData({ state: this.state, deposits, withdrawals });
        if (this.offerForm.program === MCAProgramTypes.incrementalDeal) {
          offer.deposit_freq = this.offerForm.incrementFrequency;
        }
        offer.id = 0;
        return offer;
      }),
    );
  }

  private initData() {
    this.mcaPageService
      .getLoadedState()
      .pipe(
        filter(() => !this.offerForm.fundedAmt),
        first(),
      )
      .subscribe(({ mca }) => {
        const updates = ScheduleFunctions.calcLocals(this.state, mca);
        if (updates.additionalAmt < 0) {
          Object.assign(updates, ScheduleFunctions.calcSuggested(updates, mca));
        }
        this.updateState(updates);
      });
  }

  private calcCRate(form: ScheduleOfferForm) {
    if (!form.rate || (!form.fixPayment && !form.days)) {
      return {} as McaScheduleState;
    }
    return this.calc(RateType.C);
  }

  private calcDRate(form: ScheduleOfferForm) {
    if (!form.discountFactorRate || (!form.fixPayment && !form.daysDisc)) {
      return {} as McaScheduleState;
    }
    return this.calc(RateType.D);
  }

  private watchDerivedValues() {
    this.state$
      .pipe(
        skipWhile(() => !this.mca),
        distinctUntilChanged((a, b) => a.deposits === b.deposits && a.withdrawals === b.withdrawals && a.exposureRec === b.exposureRec),
        // sync update may cause updates in wrong order
        observeOn(asyncScheduler),
        map(state => this.calcDerivedValues(state)),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntil(this.destroy$),
      )
      .subscribe(updates => this.updateState(updates));
  }

  private calcDerivedValues(state: McaScheduleState) {
    const updates = {} as McaScheduleStatePartial;
    const getStateWithUpdates = () => ScheduleFunctions.copyScheduleState(updates as McaScheduleState, state);
    Object.assign(updates, this.calcActiveCommissions(getStateWithUpdates()));
    Object.assign(updates, ScheduleFunctions.calcTrueRate(getStateWithUpdates()));
    Object.assign(updates, ScheduleFunctions.calcDiscount(getStateWithUpdates()) as McaScheduleState);
    const stateWithUpdates = getStateWithUpdates();
    updates.anulizedRet = stateWithUpdates.comAmt
      ? ((((stateWithUpdates.expectedRet - stateWithUpdates.offerForm.fundedAmt - stateWithUpdates.comAmt) /
          (stateWithUpdates.exposure + stateWithUpdates.comAmt)) *
          250) /
          stateWithUpdates.offerForm.days) *
        100
      : undefined;
    updates.expAsFundAmt = stateWithUpdates.offerForm.fundedAmt
      ? (stateWithUpdates.exposureRec.totExposure / stateWithUpdates.offerForm.fundedAmt) * 100
      : 0;
    return updates;
  }

  private calcActiveCommissions(state: McaScheduleState) {
    const result = {} as McaScheduleStatePartial;
    const loadedOffer = this.mcaPageService.get('loadedOffers')?.[0];
    if (this.mcaService.statusIsReadyForFundingOrHigher(this.mca.position.status) && !loadedOffer) {
      [result.comPct, result.comAmt] = getMcaActiveCommissions(this.mca);
      return result;
    }
    result.comPct = OfferCommissionImpl.totalCommission(state.commissions);
    result.comAmt = roundMoney(state.comPct * (state.offerForm.fundedAmt / 100));
    return result;
  }

  private calc(rateType: RateType) {
    if (this.offerForm.fixPayment && !this.offerForm.withdrAmt) {
      return {} as McaScheduleState;
    }
    try {
      const result = ScheduleFunctions.copyScheduleState(this.state);
      Object.assign(result, ScheduleFunctions.setupDayCountPayment(this.state, rateType, this.fixedNumberOfPaymetns));
      result.feeAmt = result.offerForm.fixed_cf || roundMoney((result.offerForm.fundedAmt * result.offerForm.fee) / 100);
      result.buyOutAmt = this.buyOutAmt();
      Object.assign(result, { additionalAmt: this.scheduleType.calcAdditionalAmount(result) });
      result.netToMerchantAmt = roundMoney(result.offerForm.fundedAmt - result.feeAmt - result.buyOutAmt);
      const payment = roundMoney(result.expectedRet / result.offerForm.days);
      result.savingsPct = result.totPayment ? ((result.totPayment - payment) / result.totPayment) * 100 : 0;
      return result;
    } catch (e: any) {
      this.showCalcError(e);
      return {} as McaScheduleState;
    }
  }

  private showCalcError(e: Error) {
    console.error('Calc Error: ', e);
    this.messageService.add({
      severity: 'error',
      summary: 'Calc Error',
      detail: e.toString(),
    });
  }

  private resetSchedule() {
    this.updateState({
      deposits: [],
      withdrawals: [],
    });
  }

  private calcDeposits() {
    this.scheduleType.calcDeposits();
  }

  private calcWithdrawals(discounted: boolean) {
    const daysHint = discounted ? this.offerForm.daysDisc : this.offerForm.days;
    const expectedReturn = discounted ? this.offerParams.expectedRetDisc : this.offerParams.expectedRet;
    const { withdrawals, days } =
      daysHint > 0
        ? new GenerateWithdrawalsCommand(
            this.holidaysService,
            this.offerForm.withdrStartDate,
            this.offerForm.selectedWithdFreq,
            roundMoney(expectedReturn),
            this.offerForm.withdrAmt,
            this.fixedNumberOfPaymetns ? 0 : this.minLastWithdrawal,
            daysHint,
          ).execute()
        : { withdrawals: [], days: 0 };
    if (discounted) {
      this.updateState({ offerForm: { daysDisc: days }, withdrawals });
    } else {
      this.updateState({ offerForm: { days }, withdrawals });
    }
  }

  private watchFormChanges() {
    this.offerForm$
      .pipe(
        pairwise(),
        observeOn(asyncScheduler),
        map(([prevState, offerForm]) => {
          if (this.offerForm.fundedAmt < FUNDING_ROUDING) {
            return {} as McaScheduleStatePartial;
          }
          const cRateValueChanged = offerForm.rate !== prevState.rate || offerForm.days !== prevState.days;
          if (offerForm.discountFactorRate && offerForm.discountFactorRate !== offerForm.rate) {
            const dResult = this.calcDRate(offerForm);
            const cResult = cRateValueChanged ? this.calcCRate(offerForm) : ({} as McaScheduleState);
            return ScheduleFunctions.copyScheduleState(cResult, dResult);
          }
          return this.calcCRate(offerForm);
        }),
        filter(updates => Object.keys(updates).length > 0),
        takeUntil(this.destroy$),
      )
      .subscribe(updates => this.updateState(updates));
  }

  private getTemplate() {
    return this.mcaPageService.get('offerTemplates').find(t => t.id === this.offerForm.templateId);
  }
}
